.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 1000;
}
.custom-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1E68D7;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
  }